<template>
    <v-navigation-drawer
        dark
        v-model="drawer"
    >
      <v-list>
        <v-list-item class="account-name">
            <h1 class="logo-text">
              <a href="#/dashboard">pakker
              </a>
            </h1>
        </v-list-item>
        <v-list-item
            v-for="item in items"
            :key="item.title"
            link
            :to="item.path"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>

            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <div class="pa-2">
          <v-btn block depressed @click="logout">
            <v-icon>mdi-export</v-icon> Logi välja
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
</template>

<script>
import axios from "axios";

export default {
  name: "Nav",
  data: () => ({
    items: [
      {
        title: 'Töölaud',
        icon: 'mdi-view-dashboard',
        path: '/dashboard'
      },
      {
        title: 'Saadetised',
        icon: 'mdi-package-variant-closed',
        path: '/shipments'
      },
      {
        title: 'Tooted',
        icon: 'mdi-format-list-bulleted',
        path: '/products'
      },
      {
        title: 'Maksed',
        icon: 'mdi-credit-card-outline',
        path: '/payments'
      },
      {
        title: 'Poed',
        icon: 'mdi-warehouse',
        path: '/shops'
      },
      {
        title: 'Kullerid',
        icon: 'mdi-truck-fast',
        path: '/carriers'
      },
      {
        title: 'RMP ja Ladu',
        icon: 'mdi-cached',
        path: '/erp'
      },
      {
        title: 'Seadistus',
        icon: 'mdi-cog',
        path: '/settings'
      },

    ],
  }),
  props: ['drawer'],
  methods: {
    logout: function () {
      let self = this;
      axios.get(axios.defaults.baseURL + '/sanctum/csrf-cookie')
          .then(response => {
            axios.post(axios.defaults.baseURL + '/logout')
                .then(
                    setTimeout(function(){
                      self.$router.push('/')
                    }, 250)
                )
          })
    }
  }
}
</script>

<style scoped>

</style>