import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: { title: 'Login' }
  },
  {
    path: '/shipments',
    name: 'Shipments',
    component: () => import('../views/Shipments.vue'),
    meta: { title: 'Saadetised' }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/Dashboard.vue'),
    meta: { title: 'Töölaud' }
  },
  {
    path: '/payments',
    name: 'Payments',
    component: () => import('../views/Payments.vue'),
    meta: { title: 'Maksed' }
  },
  {
    path: '/products',
    name: 'Products',
    component: () => import('../views/Products.vue'),
    meta: { title: 'Tooted' }
  },
  {
    path: '/shops',
    name: 'Shops',
    component: () => import('../views/Shops.vue'),
    meta: { title: 'Poed' }
  },
  {
    path: '/carriers',
    name: 'Carriers',
    component: () => import('../views/Carriers.vue'),
    meta: { title: 'Kullerid' }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('../views/Settings.vue'),
    meta: { title: 'Seadistus' }
  },
]

const router = new VueRouter({
  routes
})

export default router
