<template>
    <v-app id="inspire">
      <div class="d-flex flex-grow-1" v-bind:class="(!drawer) ? 'fullscreen' : ''">
        <Nav v-if="this.login" :drawer="this.drawer"></Nav>
        <Header
            v-if="this.login"
            v-on:toggle_sidebar="toggleSidebar"
           ></Header>
        <v-main>
          <v-container class="container--fluid">
            <router-view></router-view>
          </v-container>
        </v-main>
      </div>
    </v-app>
</template>
<script>
    import Nav from "./components/Nav";
    import Header from "./components/Header";
    import axios from "axios";
    export default {
      components: {Header, Nav},
      data: () => ({
        drawer: true,
        login: false,
      }),
      mounted () {
        let self = this;
        let profileUrl = axios.defaults.baseURL + '/user/profile';
        axios.get(profileUrl).then((response) => {
          document.body.classList.add('profile-loaded');
        }).catch(function (error) {
          self.$router.push('/');
        });
      },
      watch: {
        '$route.name': {
          handler: function() {
            if (this.$route.name !== 'Login') {
              this.login = true;
            } else {
              this.login = false;
            }
          },
        },
      },
      methods: {
        toggleSidebar: function () {
          this.drawer = !this.drawer;
        }
      }
    }
</script>